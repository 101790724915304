/**
 * UserLoginAPI Module
 *
 * Handles the user login data/state for the app
 * - Tokens
 * - Local storage
 * - Route authorization requests
 */
import store from 'store2';
import { redirectToPage, getUrlParams } from './BrowserAPI';
import { doFetch as podstacksAPI } from './PodstacksAPI';

const DEBUG = true;
let user = store.namespace('user'); // User storage namespace

// Create user and save to local storage
const createUserLS = (dbUser, email, token) => {
  DEBUG && console.log('Creating user...');
  // console.log (dbUser)
  // console.log(user)
  user({
    ...dbUser,
    auth: { email, token },
  });
  // console.log( user.getAll() )
  // return user.getAll()
};

// Fetches user object from local storage
const getUser = () => {
  DEBUG && console.log('Fetching user object...');
  // console.log(user.getAll())
  return user.getAll();
};

// Fetches userId from local storage
const getUserId = () => {
  DEBUG && console.log('Fetching userId...');
  return user.has('userId') ? user('userId') : null;
};

// Fetches token from local storage
const getUserToken = () => {
  DEBUG && console.log('Fetching auth token...');
  if (user.has('auth')) {
    const { email, token } = user('auth');
    return { email, token };
  } else return null;
};

// Fetches User profile from database
const getUserDB = async (email, userId = null) => {
  if (!email && userId) {
    // For admin login
    console.log('Getting user from database: ' + userId);
    return podstacksAPI('users', 'getUser', { userId });
  } else {
    console.log('Filtering user from database: ' + email);
    const users = await podstacksAPI('users', 'filterUsers', null, null, {
      filter: { identity: email },
    });
    if (users.length == 1) return users[0];
    else return null;
  }
};

// Returns if user is logged in
const isLoggedIn = () => {
  DEBUG && console.log('Current user: ' + user('userId'));
  return getUserId() != null ? true : false;
};

// Performs user login tasks
const login = async () => {
  DEBUG && console.log('Logging user in...');
  const { email, token, userId } = getUrlParams();
  if (email && authTokenExists() && (await isValidAuthToken(email, token))) {
    clearUserData();
    if (userId)
      // For admin login
      createUserLS(await getUserDB(null, userId), email, token);
    else createUserLS(await getUserDB(email), email, token);
    redirectToPage('users');
  } else {
    // DEBUG && console.log(' ERROR: Invalid email/token!');
    alert(
      'Invalid/expired login link!\nSign in with a valid account email and try again.'
    );
    redirectToPage('login');
  }
};

// Performs user logout tasks
const logout = () => {
  DEBUG && console.log('Logging user out...');
  clearUserData();
  redirectToPage('login');
};

// Check for logout flag in URL
const isLoggingOut = () => {
  DEBUG && console.log('Checking for logout flag in URL...');
  const { logout } = getUrlParams();
  // console.log({logout})
  return logout != null && logout == '1';
};

// Clear all user data
const clearUserData = () => {
  DEBUG && console.log('Clearing user data...');
  user.clear();
};

// Check for auth token in URL
const authTokenExists = () => {
  DEBUG && console.log('Checking for auth token in URL...');
  const { email, auth, token } = getUrlParams();
  console.log({ email, auth, token });
  return email != null && auth != null && token != null;
};

// Validates authorization token
const isValidAuthToken = async (email, token) => {
  DEBUG && console.log('Validating user auth token...');
  if (!email && !token) {
    DEBUG && console.log('Checking stored user auth token...');
    return podstacksAPI('auth', 'validateToken', null, getUserToken());
  } else if (email && token) {
    return podstacksAPI('auth', 'validateToken', null, { email, token });
  } else {
    return false;
  }
  // return true; // Valid token FOR DEV ONLY!
};

// Request an authorization token
const reqAuthToken = () => {
  DEBUG && console.log('Requesting user auth token...');
};

export {
  createUserLS,
  getUser,
  getUserId,
  getUserToken,
  getUserDB,
  isLoggedIn,
  login,
  logout,
  isLoggingOut,
  clearUserData,
  authTokenExists,
  isValidAuthToken,
  reqAuthToken,
};
